import {AdminActivity, EmployerConfigDto, StatsDto, StuStatsDto} from "../dto";
import {EmployerActions} from "../actions/employer";
import {VendorAction} from "../actions/vendor";
import {EmployerDetails, EmployerMetadata} from "../models";

export interface EmployerState {
    loading: boolean;
    adminActivities: AdminActivity | null;
    vendorAdminActivities: AdminActivity | null;
    stats: StatsDto | null;
    stuStats: StuStatsDto | null;
    vendorStats: StatsDto | null,
    employerDetails: EmployerDetails;
    employerConfig: EmployerConfigDto | null;
    employerMetadata: EmployerMetadata | null;
}

const defaultState: EmployerState = {
    loading: false,
    adminActivities: null,
    vendorAdminActivities: null,
    stats: null,
    stuStats: null,
    vendorStats: null,
    employerDetails: null,
    employerMetadata: null,
    employerConfig: null,
};

export default function EmployerStateReducer(state = defaultState, action: any) : EmployerState {
    switch (action.type) {
      case EmployerActions.REQUEST_STATS:
        return {
          ...state,
          stats: null,
        };
      case `${EmployerActions.REQUEST_STATS}_SUCCESS`:
        return {
          ...state,
          stats: action.payload,
        };
      case `${EmployerActions.REQUEST_STATS}_FAILURE`:
        return {
          ...state,
          stats: null,
        };
      case EmployerActions.REQUEST_STU_STATS:
        return {
          ...state,
          stuStats: null,
        };
      case `${EmployerActions.REQUEST_STU_STATS}_SUCCESS`:
        return {
          ...state,
          stuStats: action.payload,
        };
      case `${EmployerActions.REQUEST_STU_STATS}_FAILURE`:
        return {
          ...state,
          stuStats: null,
        };
      case `${EmployerActions.REQUEST_ADMIN_ACTIVITY}_SUCCESS`:
        return {
          ...state,
          adminActivities: action.payload,
        };
      case `${EmployerActions.REQUEST_ADMIN_ACTIVITY}_FAILURE`:
        return {
          ...state,
          adminActivities: { activities: [], employerAdminsDetails: [] },
        };
      case `${VendorAction.REQUEST_VENDOR_ADMIN_ACTIVITY}_SUCCESS`:
        return {
          ...state,
          vendorAdminActivities: action.payload,
        };
      case `${VendorAction.REQUEST_VENDOR_ADMIN_ACTIVITY}_FAILURE`:
        return {
          ...state,
          vendorAdminActivities: { activities: [], employerAdminsDetails: [] },
        };
      case `${VendorAction.REQUEST_VENDOR_STATS}_SUCCESS`:
        return {
          ...state,
          loading: false,
          vendorStats: action.payload,
        };
      case `${VendorAction.REQUEST_VENDOR_STATS}_FAILURE`:
        return {
          ...state,
          loading: false,
          vendorStats: null,
        };
      case EmployerActions.REQUEST_EMPLOYER_DETAILS:
        return {
          ...state,
          employerDetails: null,
        };
      case `${EmployerActions.REQUEST_EMPLOYER_DETAILS}_SUCCESS`:
        return {
          ...state,
          employerDetails: action.payload,
        };
      case `${EmployerActions.REQUEST_EMPLOYER_DETAILS}_FAILURE`:
        return {
          ...state,
          loading: false,
          employerDetails: null,
        };
      case EmployerActions.REQUEST_GET_EMPLOYER_METADATA:
        return {
          ...state,
          employerMetadata: null,
        };
      case `${EmployerActions.REQUEST_GET_EMPLOYER_METADATA}_SUCCESS`:
        return {
          ...state,
          employerMetadata: action.payload,
        };
      case `${EmployerActions.REQUEST_GET_EMPLOYER_METADATA}_FAILURE`:
        return {
          ...state,
          loading: false,
          employerMetadata: null,
        };
      case VendorAction.CLEAR_VENDOR_DATA:
        return {
          ...state,
          vendorAdminActivities: null,
          vendorStats: null,
        };
      case EmployerActions.REQUEST_EMPLOYER_CONFIG:
        return {
          ...state,
          loading: true,
        };
      case `${EmployerActions.REQUEST_EMPLOYER_CONFIG}_SUCCESS`:
        return {
          ...state,
          loading: false,
          employerConfig: action.payload,
        };
      case `${EmployerActions.REQUEST_EMPLOYER_CONFIG}_FAILURE`:
        return {
          ...state,
          loading: false,
          employerConfig: null,
        };
      default:
        return state;
    }
}