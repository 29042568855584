import {MiddlewareAction} from "../middleware";
import {VendorAction} from "./vendor";

export enum EmployerActions {
  REQUEST_STATS = "REQUEST_STATS",
  REQUEST_STU_STATS = "REQUEST_STU_STATS",
  REQUEST_ADMIN_ACTIVITY = "REQUEST_ADMIN_ACTIVITY",
  REQUEST_EMPLOYER_DETAILS = "REQUEST_EMPLOYER_DETAILS",
  REQUEST_EMPLOYER_CONFIG = "REQUEST_EMPLOYER_CONFIG",
  REQUEST_GET_EMPLOYER_METADATA = "REQUEST_GET_EMPLOYER_METADATA",
}

export function getEmployerAdminActivities(isRecentActivity?: boolean, vendorId?: string | null): MiddlewareAction {
    const params = { isRecentActivity, vendorId }
    if (!vendorId) delete params.vendorId;
    return {
        actionName: vendorId ? VendorAction.REQUEST_VENDOR_ADMIN_ACTIVITY : EmployerActions.REQUEST_ADMIN_ACTIVITY,
        type: "GET",
        url: "/employer-admin/activity",
        params
    }
}

export function getStats(employerId: string, vendorId?: string | null) {
    const params = {vendorId};
    if (!vendorId) delete  params.vendorId;
    return {
        actionName: vendorId ? VendorAction.REQUEST_VENDOR_STATS : EmployerActions.REQUEST_STATS,
        type: "GET",
        url: `/employer-admin/stats/${employerId}`,
        params
    }
}

// Note: Not handleling `vendorId` here
export function getStuStats(employerId: string): MiddlewareAction {
  return {
    actionName: EmployerActions.REQUEST_STU_STATS,
    type: "GET",
    url: `/employer-admin/salary-topup-stats?employerId=${employerId}`,
  };
}

export function getUserEmployerInfo(): MiddlewareAction {
    return {
        actionName: EmployerActions.REQUEST_EMPLOYER_DETAILS,
        type: "GET",
        url: "/employer-admin/employer-info",
    }
}

export function getUserEmployerMetadata(): MiddlewareAction {
    return {
        actionName: EmployerActions.REQUEST_GET_EMPLOYER_METADATA,
        type: "GET",
        url: "/employer-admin/employer-metadata",
    }
}


export function getEmployerConfig():MiddlewareAction{
    return {
        actionName: EmployerActions.REQUEST_EMPLOYER_CONFIG,
        type: "GET",
        url: "/employer-admin/employer-config"
    }
}
