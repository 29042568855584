import React from "react";
import {Switch, Route, HashRouter} from "react-router-dom";
import Attendance from "./attendance/attendance";
// import Leave from "./leave/leave";
import Payroll from "./payroll";
import Profile from "./profile";
import {RoutePaths} from "../../models";
import Home from "./home";
import EmployeeProfile from "./employees/employeeProfile";
import DocumentUpload from "../document-upload";
import {CurrentEmployees} from "./employees/currentEmployees";
import Transactions from "./all-transactions/transactions";
import RoleSettings from "./settings/roleSettings";
import VendorDashboard from "./vendors/vendorDashboard";
import UserVerification from "./user-verification";
import AdvanceSalaries from "./advance-salaries";

//TODO: refactor conditional routing
export function getRoutes(context?: { isEmployeeApprovalEnabled?: boolean; isSalaryTopupViewEnabled?: boolean } | null){
    const { isEmployeeApprovalEnabled } = context || {};
    return (
            <HashRouter>
                <Switch>
                    <Route exact path={"/"} component={Home}/>
                    <Route exact path={RoutePaths.ATTENDANCE} component={Attendance}/>
                    <Route exact path={RoutePaths.EMPLOYEES} component={CurrentEmployees}/>
                    <Route path={RoutePaths.PAYROLL} component={Payroll}/>
                    {/* <Route exact path={RoutePaths.LEAVE} component={Leave}/> */}
                    <Route exact path={RoutePaths.PROFILE} component={Profile}/>
                    <Route exact path={RoutePaths.DASHBOARD} component={Home}/>
                    <Route exact path={RoutePaths.DOCUMENT_UPLOAD} component={DocumentUpload}/>
                    <Route exact path={RoutePaths.EMPLOYEE_PROFILE} component={EmployeeProfile}/>
                    <Route exact path={RoutePaths.EMPLOYERS_TRANSACTIONS} component={Transactions}/>
                    <Route exact path={RoutePaths.SALARY_TOPUPS} component={AdvanceSalaries}/>
                    <Route exact path={RoutePaths.SETTINGS} component={RoleSettings}/>
                    <Route path={RoutePaths.VENDORS} component={VendorDashboard}/>
                    {isEmployeeApprovalEnabled && <Route exact path={RoutePaths.USER_VERIFICATION} component={UserVerification}/>}
                </Switch>
            </HashRouter>

    )
}